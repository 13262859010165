<template>
  <div>
    <div class="mb-5 flex justify-between items-center">
      <div class="text-lg font-bold">Account Information</div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        Account Name
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ accountName }}
      </div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        Account Number
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ accountNo }}
      </div>
    </div>
    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        Bank Name
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ bankName }}
      </div>
    </div>

    <div class="border-t border-blue-200 mb-5 pt-4">
      <div class="opacity-75 text-sm font-medium mb-1">
        BVN
      </div>
      <div class="text-gray-500 text-sm font-light flex flex-row items-center">
        {{ bvn }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    accountInformation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    accountNo() {
      return this.accountInformation?.account_no ?? '-------';
    },
    accountName() {
      return this.accountInformation?.account_name ?? '----';
    },
    bankName() {
      return this.accountInformation?.bank_name
        ? this.$store.state.banks?.find(
            bank => bank?.code === this.accountInformation?.bank_name
          )?.name
        : '------';
    },
    bvn() {
      return this.accountInformation?.bvn;
    }
  }
};
</script>
